import React, { useEffect, useState, useContext } from "react";
import { withStyles } from "@mui/styles";
import { FeatureGroup, Polygon, withLeaflet, Circle, SVGOverlay } from "react-leaflet";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { LatLng } from "leaflet";
import { toJS } from "mobx";
import proj4 from "proj4";
import Proj4Leaflet from "proj4leaflet";
import L from "leaflet";
import flip from "@turf/flip";
import useThreeDMCViewerStyles from "./ThreeDMCViewerStyles";

/**
 *
 *
 */
const ThreeDMCViewer = hooksObserver(({ classes, leaflet }) => {
  const {
    mapStore: { bounds, mapLayer: { activeMap }, mappingCloud: { mcActive, footprints, fovs, fetchFootprints, fetchFovs, svgLayer, pickPosition, pingFootprints } },
  } = useContext(StoresContext);

  useEffect(() => {
    const map = leaflet.map;
    const askFootprints = () => {
      const bounds = map.getBounds();
      fetchFootprints(bounds, map);
    };
    const askFovs = () => {
      fetchFovs();
    };
    if (mcActive && !pingFootprints) {
      //console.log("enable binding map change to send bounds if mcActive");
      map.on("zoomend", () => {
        askFovs(), askFootprints();
      });
      map.on("dragend", () => {
        askFovs(), askFootprints();
      });
      askFootprints();
      askFovs();
    } else if (mcActive && pingFootprints) {
      askFootprints();
    } else {
      //console.log("disable binding map change to send bounds if mcActive");
      map.off("zoomend");
      map.off("dragend");
      svgLayer.selectAll("*").remove();
    }
  }, [mcActive, pingFootprints]);

  useEffect(() => {
    const map = leaflet.map;
    svgLayer.selectAll("*").remove();
    fovs.map((fov) => {
      //console.log(toJS(fov));
      const center = map.latLngToLayerPoint(new L.LatLng(fov.spatialPoint.y, fov.spatialPoint.x));
      svgLayer
        .append("path")
        .attr("d", fov.path[1])
        .attr("stroke", `rgba(${fov.color.red},${fov.color.green},${fov.color.blue},${fov.color.alpha / 255})`)
        .attr("stroke-width", 2)
        .attr("fill", `rgba(${fov.fillColor.red},${fov.fillColor.green},${fov.fillColor.blue},${fov.fillColor.alpha / 255})`)
        .attr("style", "z-index: 500")
        .attr("transform", "translate(" + center.x + "," + center.y + ")");
      svgLayer
        .append("text")
        .attr("x", fov.labelPosition.x)
        .attr("y", fov.labelPosition.y)
        .text(fov.label)
        .attr("font-family", fov.labelFontName)
        .attr("font-size", `${fov.labelFontSize}px`)
        .attr("fill", `rgba(${fov.labelColor.red},${fov.labelColor.green},${fov.labelColor.blue},${fov.labelColor.alpha / 255})`)
        .attr("style", "z-index: 501")
        .attr("transform", "translate(" + center.x + "," + center.y + ")");
    });
  }, [fovs, footprints]);

  return (
    <FeatureGroup>
      {mcActive &&
        footprints.length > 0 &&
        footprints.map((footprint) => {
          if (!footprint.spatialPoint) return false;
          return (
            <Circle
              key={footprint.objectId}
              center={{
                lat: footprint.spatialPoint.y,
                lng: footprint.spatialPoint.x,
              }}
              radius={1}
              className={pickPosition ? classes.pickfootprint : classes.footprint}
            />
          );
        })}
    </FeatureGroup>
  );
});

ThreeDMCViewer.propTypes = {};

export default withLeaflet(withStyles(useThreeDMCViewerStyles)(ThreeDMCViewer));
