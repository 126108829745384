import { Button, MenuItem, Switch as SwitchUI, TextField, Typography, IconButton } from "@mui/material";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { makeStyles, withStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Business";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { AutoComplete, Header, HeaderFab } from "@orbit/components";
import { StoresContext } from "contexts";
import L from "leaflet";
import deburr from "lodash/deburr";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { ROUTE_SELECTEER_PROFIEL, ROUTE_PUBLIC_INVESTIGATION } from "routes/RouteList";
import { fetchMunicipality, getSearchDataCapakeys, getSearchDataStreets } from "services/api";
import { getUserRights } from "services/auth";
import ModalView from "./advanced-search/ModalView";
import styles from "./HeaderViewStyles";
import messages from "./messages";
import { LOGGING_MODE_ENUM } from "stores/models/LoggingModel";
import { TOUR_ENUM } from "stores/UiStore";
import { perceelSteps, headerSteps, roSteps } from "./steps";
import { useRouteMatch } from "react-router";

const customStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  geavanceerd: {
    position: "relative",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: theme.palette.grey[200],
    fontWeight: 400,
    fontSize: "13.3333px",
    minHeight: 39,
    textTransform: "initial",
    borderColor: alpha(theme.palette.common.black, 0.15),
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
  },
}));

const HeaderView = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    uiStore: {
      sideBarOpen,
      setSideBarOpen,
      showTour,
      setShowTour,
      tourIndex,
      setTourIndex,
      tourType,
      popupModel: { getDataForLocationFromLatLng, getDataForCapakey },
      setDialogSelectNisOpen,
    },
    mapStore: { setLatLng, setBounds, setZoomLevel },
    authStore: { doLogout, sessionId, setAvailableProfiles, municipality, nis, niscodes },
    applicationStore: { logging: { mode, toggleMode } },
    authStore,
    routingStore: { push, location },
  } = useContext(StoresContext);

  const [munGeometry, setMunGeometry] = useState(null);
  const [useStepper, setUseStepper] = useState(headerSteps);
  let match = useRouteMatch(ROUTE_PUBLIC_INVESTIGATION);

  useEffect(() => {
    const fetchMun = async () => {
      const munData = await fetchMunicipality(nis, "actual");
      const geom = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: JSON.parse(munData.geometry.boundingBox).coordinates,
        },
      };
      setMunGeometry(geom);
      //setBounds(L.geoJson(geom).getBounds());
    };
    fetchMun();
  }, [nis, municipality]);

  const infoFab = (
    <HeaderFab
      key="help-fab"
      id="tour"
      fabmenuid="app-help"
      fabicon={<LiveHelpIcon style={{ color: "#ffffff" }} />}
      fabactions={[
        {
          title: `${showTour ? "Sluit" : "Open"} rondleiding`,
          funct: () => {
            if (showTour) {
              setShowTour(false);
            } else {
              if (match) {
                setShowTour(true, TOUR_ENUM.RUIMTELIJK_ONDERZOEK);
              } else {
                setShowTour(true, TOUR_ENUM.HEADER);
              }
            }
          },
        },
        {
          title: "Documentatie",
          funct: async () => {
            open("https://orbitgis.atlassian.net/wiki/external/OGJiZGU0ODUwMDFlNGQ0YWExODYwYWI5MzE1MTQ4ZDc", "_blank");
          },
        },
      ]}
    />
  );

  const districtFab =
    niscodes.length > 0 ? (
      <HeaderFab
        key="district-fab"
        fabmenuid="app-district"
        fabtitle={municipality}
        fabicon={
          <HomeIcon
            data-test-id="btn-municipality"
            style={{
              fontSize: "1.5rem",
              marginLeft: 12,
            }}
          />
        }
        fabactions={[
          {
            title: "Zoom",
            funct: () => {
              setBounds(L.geoJson(munGeometry).getBounds());
            },
          },
          {
            title: "Verander klant",
            funct: async () => {
              setDialogSelectNisOpen(true);
            },
          },
        ]}
      />
    ) : (
      <HeaderFab
        key="district-fab"
        fabmenuid="app-district"
        fabtitle={municipality}
        fabicon={
          <HomeIcon
            style={{
              fontSize: "1.5rem",
              marginLeft: 12,
            }}
          />
        }
        fabaction={() => {
          setBounds(L.geoJson(munGeometry).getBounds());
        }}
      />
    );

  const userFab = (
    <HeaderFab
      id="user-fab"
      key="user-fab"
      fabmenuid="app-profile"
      fabtitle={authStore.role}
      fabicon={
        <AccountCircleIcon
          style={{
            fontSize: "1.5rem",
            marginLeft: 12,
          }}
        />
      }
      fabactions={[
        {
          title: "Profiel wijzigen",
          funct: (e) => {
            const fetchRights = async () => {
              if (authStore.sessionId !== null) {
                const rights = await getUserRights(sessionId);
                setAvailableProfiles(rights);
              }
              push(ROUTE_SELECTEER_PROFIEL);
            };
            fetchRights();
          },
        },
        {
          title: "Uitloggen",
          funct: (e) => doLogout(),
        },
      ]}
    />
  );

  const onSelectValue = async (item) => {
    if (item.type === "street") {
      setLatLng(item.location);
      setZoomLevel(18);
      await getDataForLocationFromLatLng(item.location.lat, item.location.lon, item.value.split(",")[0].split(" ").length > 1 ? true : false);
    } else {
      await getDataForCapakey(item.value, true);
    }
  };

  const SearchComp = (props) => {
    const customClasses = customStyles();

    const [entries, setEntries] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    return (
      <Fragment>
        <AutoComplete
          id="header-search"
          data-test-id="header-search"
          placeholder="Zoek adres of capakey ..."
          noResult="Geen resultaten"
          entries={entries}
          onChange={async (value) => onSelectValue(value)}
          apiCall={async (value) => {
            var re = new RegExp("\\d{5}[A-Z_]{1}\\d{4}\\/\\d{2}[A-Z_]\\d{3}");
            const [result] = value.match(re) || [null];
            const isCapakey = result === value;
            const { data } = value ? (isCapakey ? await getSearchDataCapakeys(value) : await getSearchDataStreets(value)) : { data: [] };
            setEntries(data);
          }}
          classes={classes}
        />
        <Button id="header-search-advanced" data-test-id="header-search-advanced" variant="text" size="medium" className={customClasses.geavanceerd} onClick={handleClickOpen}>
          Uitgebreid zoeken...
        </Button>
        <ModalView open={open} setOpen={setOpen} />
      </Fragment>
    );
  };

  const switchComp = (props) => {
    const [selectedValue, setSelectedValue] = useState("a");

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    return (
      <Fragment>
        <Typography variant="subtitle1">Applicatie logs</Typography>
        <SwitchUI checked={mode === LOGGING_MODE_ENUM.MAGDA} onChange={toggleMode} value="checkedA" color="abcd" />
        <Typography variant="subtitle1">Magda logs</Typography>
      </Fragment>
    );
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      // this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      setTourIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || [ACTIONS.CLOSE].includes(action)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTourIndex(0);
    }
  };

  useEffect(() => {
    switch (tourType) {
      case TOUR_ENUM.HEADER:
        setUseStepper(headerSteps);
        break;
      case TOUR_ENUM.PERCEELINFO:
        if (!match) {
          setUseStepper(perceelSteps);
        }
        break;
      case TOUR_ENUM.RUIMTELIJK_ONDERZOEK:
        if (match) {
          setUseStepper(roSteps);
        }
        break;
      default:
        if (match) {
          setUseStepper(roSteps);
        } else {
          setUseStepper(headerSteps);
        }
        break;
    }
  }, [tourType]);

  return (
    <>
      <Header
        applicationTitle={formatMessage(messages.title)}
        SearchComponent={authStore.role !== "Logging" ? !location.pathname.includes("ruimtelijk-onderzoek") && SearchComp : switchComp}
        sideBarOpen={authStore.role !== "Logging" && sideBarOpen}
        setSideBarOpen={authStore.role !== "Logging" ? setSideBarOpen : () => {}}
        headerFabs={[infoFab, districtFab, userFab]}
      />
      <Joyride
        stepIndex={tourIndex}
        callback={handleJoyrideCallback}
        steps={useStepper}
        continuous={true}
        run={showTour}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlay={true}
        disableScrolling={true}
        locale={{
          back: "Terug",
          close: "Sluiten",
          last: "Einde",
          next: "Volgende",
          skip: "Overslaan",
        }}
        styles={{
          options: {
            overlayColor: "#fff",
            primaryColor: "#d32f2f",
            textColor: "#000",
            width: undefined,
            zIndex: 10000,
          },
        }}
      />
    </>
  );
});

export default withStyles(styles)(injectIntl(HeaderView));

function renderInput(inputProps) {
  const { InputProps, value, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      value={value}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function getSuggestions(suggestions, value, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter((suggestion) => {
        const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}
